@media (min-width: 1281px) {
    .topBar {
        position: fixed;
        top: 0;
        background-color: #223F67;
        height: 60px;
        width: 100%;
        max-width: 600px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin: 0px auto;
        color: white;
        font-weight: bold;
        font-size: 20px;
        z-index: 1000;
    }
}

.topBar {
    position: fixed;
    top: 0;
    background-color: #223F67;
    height: 60px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 0px auto;
    color: white;
    font-weight: bold;
    font-size: 20px;
    z-index: 1000;
}