.menu-transition {
  /* animation: fade 200ms ease-out; */
}

.menu-image {
    position: fixed;
    height: 230px;
    z-index: -1;
    /* animation: fade 200ms ease-out; */
    width: 100%;
}

.menu-back-ltr {
  position: fixed;
  z-index: 1000;
  top: 0;
  margin: 10px;
  color: white;
  font-weight: bold;
  text-shadow: 2px 0 0 rgba(0, 0, 0, 0.3), -2px 0 0 rgba(0, 0, 0, 0.3), 0 2px 0 rgba(0, 0, 0, 0.3), 0 -2px 0 rgba(0, 0, 0, 0.3),
    1px 1px rgba(0, 0, 0, 0.3), -1px -1px 0 rgba(0, 0, 0, 0.3), 1px -1px 0 rgba(0, 0, 0, 0.3), -1px 1px 0 rgba(0, 0, 0, 0.3);
}

.menu-back-rtl {
  position: fixed;
  transform: rotate(180deg);
  z-index: 1000;
  top: 0;
  margin: 10px;
  color: white;
  font-weight: bold;
  text-shadow: 2px 0 0 rgba(0, 0, 0, 0.3), -2px 0 0 rgba(0, 0, 0, 0.3), 0 2px 0 rgba(0, 0, 0, 0.3), 0 -2px 0 rgba(0, 0, 0, 0.3),
    1px 1px rgba(0, 0, 0, 0.3), -1px -1px 0 rgba(0, 0, 0, 0.3), 1px -1px 0 rgba(0, 0, 0, 0.3), -1px 1px 0 rgba(0, 0, 0, 0.3);
}

.menu-page {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.menu-container {
  background-color: transparent;
  /* height: 100%; */
  position: relative;
}

.menu-header {
  position: -webkit-sticky;
  position: sticky;
  background-color: transparent;
  top: 0;
  z-index: 1;
}

.menu-info {
  background-color: red;
  display: block;
  /* font-family: Muli; */
  text-align: center;
  width: 100%;
  margin-bottom: 10px;
  padding: 0px 20px;
}

.menu-gutter {
  position: relative;
  /* top:150px; */
  /* font-family: Muli; */
  text-align: center;
  width: 100%;
  height: 90px;
  border-radius: 40px 40px 0px 0px;
  background-color: white;
  font-weight: bold;
  font-size: 30px;
  text-align: center;
  padding-top: 10px;
}

.menu-gutter-title {
  margin: 0px 20px;
}

.tagbox {
  display: flex;
  justify-content: start;
  align-items: center;
  flex-wrap: wrap;
}
.tagbox-product {
  display: flex;
  justify-content: start;
  align-items: center;
  flex-wrap: wrap;
}

.tag {
  display: flex;
  width: fit-content;
  position: relative;
  margin: 10px 10px 10px 0;
  color: rgb(234, 139, 49);
  font-weight: bold;
}

.chip {
  padding: 3px 20px;
  border-radius: 25px;
  background-color: rgb(4, 136, 72);
  color: white;
}
