* {
    box-sizing: border-box;
  }

.table-input {
    width: 100%;
    text-align: center;
}

.table-input-desc {
    font-family: Muli;
    font-size: 18px;
    color: black;
    padding: 20px 0px;
}

.table-input input {
    font-family: Muli;
    font-size: 30px;
    width: 30%;
    text-align: center;
    display: inline-block;
    border-radius: 10px;
    border-width: 1px;
    border-color: black;
    border-style: solid;
}