.queue {
    display: flex;
    flex-direction: row;
    width: 100%;
    font-family: Muli;
    font-weight: bold;
    font-size: 16px;
    line-height: 16px;
    align-items: center;
    justify-content: center;
}

.queue-label {
    padding-top: 10px;
    text-align: center;
    display: flex;
    flex-direction: row;
}

.queue-label-img {
    content: url('../../../images/chrono.jpg');
    height: 15px;
    width: 15px;
    margin-right: 10px;
}