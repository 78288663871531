.radioSelect {
    display: flex;
    flex-direction: column;
    width: auto;
    font: Muli;
    min-height: 50px;
    width: 100%;
}

.radioSelect-name {
    padding: 0px 20px;
    width: 100%;
    min-height: 50px;
    font-size: 18px;
    font-weight: bold;
    align-items: left;
}

.radioSelect-option {
   display: flex;
   flex-direction: row;
   min-height: 50px;
   width: 100%;
   border-top: 1px solid lightgray;
}

.radioSelect-option-name {
    padding: 0px 40px;
    width: 90%;
    line-height: 50px;
}

.radioSelect-option-name-bold {
    font-weight: bold;
    padding: 0px 40px;
    width: 90%;
    line-height: 50px;
}

.radioSelect-option-description {
    padding: 0px 40px;
    min-height: 50px;
    width: 90%;
    margin-bottom: 20px;
}

.radioSelect-input {
    display: block;
    width: 2em;
    height: 2em;
    border-radius: 50%;
    border: 0.1em solid #223F67;
    margin: auto 2em;
}

.radioSelect-input:checked {
    background: radial-gradient(#223F67 50%, rgba(255, 0, 0, 0) 51%);
}

.radioSelect-input:focus {
    box-shadow: 0 0 0 0.05em #fff, 0 0 0.15em 0.1em #223F67;
}

.radioSelect-input-multi {
    display: block;
    width: 2em;
    height: 2em;
    border: 0.1em solid #223F67;
    margin: auto 2em;
}

.radioSelect-input-multi:checked {
    border: 0.2em solid #223F67;
    background: content-box #19ccbd;
}

.radioSelect-input-multi:focus {
    box-shadow: 0 0 0 0.05em #fff, 0 0 0.15em 0.1em #223F67;
}

.radioSelect-children {
    margin-left: 30px;
    border-left: .5em solid lightgray;
}