.venue-page {
    /* animation: fade 250ms ease-out; */
}

.venue-header {
  background-color: white;
} 

.venue-header-title {
  display: flex;
  font-size: 20px;
}

.venue-header-title-big {
  display: flex;
  font-size: 30px;
}

/*
Dropdown menu
*/

.Dropdown-root {
    position: relative;
    width: 65px;
    line-height: 38px;
}

.Dropdown-control {
    position: relative;
    overflow: hidden;
    background-color: transparent;
    box-sizing: border-box;
    color: #333;
    cursor: default;
    outline: none;
    padding: 0px 40px 0px 10px;
    transition: all 200ms ease;
}

.Dropdown-control:hover {
    /* box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06); */
}

.Dropdown-arrow {
    border-color: #999 transparent transparent;
    border-style: solid;
    border-width: 5px 5px 0;
    content: ' ';
    display: block;
    height: 0;
    margin-top: -ceil(2.5);
    position: absolute;
    right: 18px;
    top: 22px;
    width: 0
}

.is-open .Dropdown-arrow {
    /* border-color: transparent transparent #999;
    border-width: 0 5px 5px; */
}

.Dropdown-menu {
    background-color: white;
    border: 1px solid #ccc;
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
    box-sizing: border-box;
    margin-top: -1px;
    max-height: 200px;
    overflow-y: auto;
    position: absolute;
    text-align: center;
    top: 100%;
    width: 100%;
    z-index: 1000;
    -webkit-overflow-scrolling: touch;
}

.Dropdown-menu .Dropdown-group>.Dropdown-title {
    padding: 8px 10px;
    color: rgba(51, 51, 51, 1);
    font-weight: bold;
    text-transform: capitalize;
}

.Dropdown-option {
    box-sizing: border-box;
    color: rgba(51, 51, 51, 0.8);
    cursor: pointer;
    display: block;
    padding: 8px 10px;
}

.Dropdown-option:last-child {
    border-bottom-right-radius: 2px;
    border-bottom-left-radius: 2px;
}

.Dropdown-option:hover {
    background-color: #f2f9fc;
    color: #333;
}

.Dropdown-option.is-selected {
    background-color: #f2f9fc;
    color: #333;
}

.Dropdown-noresults {
    box-sizing: border-box;
    color: #ccc;
    cursor: default;
    display: block;
    padding: 8px 10px;
}

.dropdown-flag {
    border: 1px solid lightgray;
}