.costContainer {
    font-weight: bold;
    font-family: Muli;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
}

.costClose {
    margin-top: 10px;
    margin-left: 10px;
    margin-right: auto;
}

.costInfo {
    margin-top: 25px;
    display: flex;
    flex-direction: column;
}

.costInfo .costName {
    text-align: center;
    font-size: 18px;
    font-weight: bold;
}

.costInfo .costDesc {
    text-align: center;
    font-size: 14px;
    padding: 15px 20px;
}

.costAmount {
    height: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0px 80px;
    font-size: 42px;
    align-items: baseline;
}

.costContainerButton {
    background-color: #223F67;
    border-color: #223F67;
    border-radius: 20px;
    font-size: 18px;
    line-height: 40px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    margin-top: 60px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 25px;
    color: white;
    height: 40px;
    width: 150px;
}
