.product-fp {
    display: flex;
    flex-direction: column;
    -webkit-overflow-scrolling: touch;
    overflow: auto;
    animation: fade 250ms ease-out;
}

.product-fp-close {
    display: block;
    position: fixed;
    top: 0;
    margin: 10px;
    background-color: rgba(40, 63, 100, 1);
    font-family: Muli;
    color: white;
    height: 36px;
    width: 36px;
    font-size: 34px;
    text-align: center;
    line-height: 28px;
    border-radius: 18px;
    z-index: 1;
}

.product-fp-image-container-landscape {
    width: 100%;
    padding-top: 66%; /* 3:2 Aspect Ratio */
    position: relative;
}

.product-fp-image-container-portrait {
    width: 100%;
    padding-top: 100%; /* 1:1 Aspect Ratio */
    position: relative;
}

.product-fp-image {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

.product-fp-image-img {
    object-fit: cover;
    height: 100%;
    width: 100%;
}

.product-fp-info {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 20px;
    /* font-family: Muli; */
    color: rgba(40, 63, 100, 1);
    position: relative;
}

.product-fp-title {
    display: flex;
    flex-direction: row;
    font-weight: bold;
    font-size: 22px;
    margin: 0px 20px;
    justify-content: space-between;
    position: relative;
}

.product-fp-name {
    width: 75%;
}

.product-fp-desc {
    font-size: 16px;
    float: left;
    margin: 10px 20px;
    white-space: pre-line;
}

.product-fp-purchase {
    display: flex;
    flex-direction: row;
    width: 100%;
    text-align: center;
    justify-content: center;
    margin: 20px auto;
}

.product-fp-amount-update {
    margin: 0px 15px;
    border-radius: 8px;
    border: 1px solid lightgray;
    font-size: 30px;
    height: 40px;
    width: 40px;
    line-height: 32px;
}

.product-fp-amount-value {
    margin: 0px 20px;
    font-size: 30px;
    font-weight: bold;
}

.product-fp-footer {
    display: flex;
    justify-content: center;
    width: 100%;
    background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 1), rgba(255, 255, 255, 1), rgba(255, 255, 255, 1));
    position: fixed;
    bottom: 0;
    height: 100px;
}

.product-fp-recommended {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
}

.product-fp-recommended-title {
    display: flex;
    flex-direction: column;
    background-color: white;
    color: rgba(40, 63, 100, 1);
    padding: 0px 20px;
    margin-bottom: 20px;
    height: 30px;
    font-size: 20px;
    /* font-family: Muli; */
    font-weight: bold;
}

.product-fp-recommended-product {
    background-color: white;
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 110px;
    font-weight: normal;
    font-size: 16px;
    padding: 0px 20px;
    border-bottom: 1px solid lightgray;
}

.product-fp-recommended-product-selected {
    background-color: lightcyan;
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 110px;
    font-weight: normal;
    font-size: 16px;
    padding-left: 20px;
    border-bottom: 1px solid lightgray;
}

.product-fp-recommended-product-info {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    color: rgba(40, 63, 100, 1);
}

.product-fp-recommended-product-name {
    font-weight: bold;
    margin-top: 10px;
}

.product-fp-recommended-product-desc {
    display: flex;
    height: 45px;
    align-items: center;
    font-size: 14px;
    white-space: pre-line;
}

.product-fp-recommended-product img {
    border-radius: 4px;
    margin: auto 10px;
}

.product-fp-recommended-product-selected img {
    border-radius: 4px;
    margin: auto 10px;
}

.product-fp-footer-button {
    display: flex;
    flex-direction: row;
    background-color: #223F67;
    border-color: #223F67;
    border-radius: 28px;
    font-size: 18px;
    font-weight: bold;
    margin: 20px auto;
    color: white;
    height: 56px;
    line-height: 56px;
    width: 65%;
    position: fixed;
    bottom: 0;
    justify-content: space-between;
}

.product-fp-footer-button:disabled,
.product-fp-footer-button[disabled] {
    display: flex;
    flex-direction: row;
    background-color: lightgray;
    border-color: lightgray;
    border-radius: 28px;
    font-size: 18px;
    font-weight: bold;
    margin: 20px auto;
    color: white;
    height: 56px;
    line-height: 56px;
    width: 65%;
    position: fixed;
    bottom: 0;
    justify-content: space-between;
}

@media (min-width: 1281px) {
    .product-fp-footer-button {
        max-width: 300px;
    }
    .product-fp-button {
        max-width: 600px;
    }
    .product-fp-footer {
        max-width: 600px;
    }
}

.product-fp-inset {
    height: 100px;
}


/* Costs */

.product-fp-cost {
    display: flex;
    flex-direction: row;
    margin: 0px 20px;
    border-radius: 8px;
    border: 1px solid rgba(255, 236, 0, 0.15);
    background-color: rgba(255, 236, 0, 0.15);
}

.product-fp-cost-price {
    display: flex;
    flex-direction: row;
}

.product-fp-cost-info {
    display: flex;
    flex-direction: column;
    margin: 5px 20px;
}

.product-fp-cost-name {
    font-weight: bold;
}

.product-fp-cost-purchase {
    float: right;
    display: flex;
    flex-direction: row;
    text-align: center;
    margin: auto;
    top: 50%;
}

.product-fp-cost-amount-update {
    margin: 0px 15px;
    border-radius: 8px;
    border: 1px solid lightgray;
    font-size: 20px;
    height: 24px;
    width: 24px;
    line-height: 18px;
}

.product-fp-cost-amount-value {
    font-size: 22px;
    font-weight: bold;
    line-height: 22px;
}


/* Sets */

.product-fp-subproduct {
    display: flex;
    flex-direction: row;
    min-height: 110px;
    border-bottom: 1px solid lightgray;
    width: auto;
}

.product-fp-subproduct-card {
    display: flex;
    flex-direction: row;
    width: calc(100vw - 40px);
    margin: 0px 20px 10px 20px;
    justify-content: space-between;
}

.product-fp-subproduct-info {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 5px 0px;
    position: relative;
}

.product-fp-subproduct-image {
    height: 75px;
    width: 75px;
    margin: 10px 0px;
    border-radius: 4px;
}

.product-fp-subproduct-image img {
    border-radius: 4px;
}

.product-fp-subproduct-np {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.product-fp-subproduct-name {
    font-weight: bold;
    width: 100%;
}

.product-fp-subproduct-price {
    font-weight: bold;
}

.product-fp-subproduct-desc {
    height: 45px;
    width: 100%;
    white-space: pre-line;
}

.product-fp-subproduct-purchase {
    display: flex;
    flex-direction: row;
    text-align: center;
    float: left;
    margin-left: -20px;
}

.product-fp-subproduct-amount-update {
    margin: 5px 15px 15px 15px;
    border-radius: 8px;
    border: 1px solid lightgray;
    font-size: 30px;
    height: 30px;
    width: 30px;
    line-height: 24px;
}


/* Bundles */

.product-fp-bundle-category {
    font-size: 18px;
    font-weight: bold;
    margin-top: 30px;
    color: #223F67;
    position: relative;
}

.product-fp-bundle-category-name {
    font-size: 22px;
    height: auto;
    line-height: 30px;
    font-weight: bold;
    padding: 0px 20px;
    background-color: white;
}

.product-fp-bundle-product {
    background-color: white;
    display: flex;
    flex-direction: row;
    width: 100%;
    min-height: 110px;
    font-weight: normal;
    font-size: 16px;
    padding: 0px 20px;
    border-bottom: 1px solid lightgray;
}

.product-fp-bundle-product-highlighted {
    /* background-color: rgba(255, 236, 0, 0.2); */
    background-color: lightcyan;
    display: flex;
    flex-direction: row;
    width: 100%;
    min-height: 110px;
    font-weight: normal;
    font-size: 16px;
    padding: 0px 20px;
}

.product-fp-bundle-product-info {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    position: relative;
}

.product-fp-bundle-product-name {
    font-weight: bold;
    margin-top: 10px;
}

.product-fp-bundle-product-desc {
    display: flex;
    min-height: 55px;
    margin-bottom: 20px;
    align-items: center;
    white-space: pre-line;
}

.product-fp-bundle-product-price {
    font-weight: bold;
    margin-bottom: 10px;
}

.product-fp-bundle-product img {
    border-radius: 4px;
    margin: auto 10px;
}

.product-fp-bundle-product-highlighted img {
    border-radius: 4px;
    margin: auto 10px;
}

.product-dropdown {
    /* font-family: Muli; */
    font-weight: bold;
    width: auto;
    border: 1px solid rgb(239, 249, 255);
    border-radius: 8px;
    background-color: rgb(239, 249, 255);
    margin: 20px;
}

.product-dropdown-arrow {
    border-color: #999 transparent transparent;
    border-style: solid;
    border-width: 5px 5px 0;
    content: ' ';
    display: block;
    height: 0;
    position: absolute;
    right: 18px;
    top: 18px;
    width: 0
}