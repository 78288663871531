.info-container {
    background-color : transparent;
    display: flex;
    flex-direction: column;
    font-family: Muli;
    width: 100%;
    overflow: hidden;
    text-align: center;
}

.info-opening-hours {
    font-size: 16px;
    font-weight: normal;
    color: #223F67;
    padding-top: 5px;
    width: 100%;
}

.info-website {
    font-size: 16px;
    padding-top: 5px;
}

.info-happy-hours {
    font-size: 16px;
    font-weight: normal;
    background-color: #FFEC00;
    /* height: 50px; */
    /* line-height: 50px; */
    padding:15px;
    border-radius: 25px;
    color: #223F67;
    margin: 10px 0px;
}

.info-social {
    display: flex;
    flex-direction: row;
}

.info-instagram img{
    height: 40px;
    width: auto;
    padding: 0px 5px;
}

.info-facebook img{
    height: 40px;
    width: auto;
    padding: 0px 5px;
}