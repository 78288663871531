@media (min-width: 1281px) {
    .order-page {
        height: 100%;
        width: 100%;
        font-family: Muli;
        display: flex;
        flex-direction: column;
        animation: fade 250ms ease-out;
        max-width: 600px;
    }

    .order-footer {
        position: fixed;
        bottom: 0;
        display: flex;
        flex-direction: row;
        background-color: #223F67;
        justify-content: center;
        align-items: center;
        margin: 0px auto;
        height: 60px;
        width: 100%;
        max-width: 600px;
        color: white;
        font-weight: bold;
        font-size: 20px;
    }
    
    .order-footer-disabled {
        position: fixed;
        bottom: 0;
        display: flex;
        flex-direction: row;
        background-color: rgb(170, 170, 170);
        justify-content: center;
        align-items: center;
        margin: 0px auto;
        height: 60px;
        width: 100%;
        max-width: 600px;
        color: white;
        font-weight: bold;
        font-size: 20px;
    }
}

.order-page {
    height: 100%;
    width: 100%;
    font-family: Muli;
    display: flex;
    flex-direction: column;
    animation: fade 250ms ease-out;
}

.order-footer {
    position: fixed;
    bottom: 0;
    display: flex;
    flex-direction: row;
    background-color: #223F67;
    justify-content: center;
    align-items: center;
    margin: 0px auto;
    height: 60px;
    width: 100%;
    color: white;
    font-weight: bold;
    font-size: 20px;
}

.order-footer-disabled {
    position: fixed;
    bottom: 0;
    display: flex;
    flex-direction: row;
    background-color: rgb(170, 170, 170);
    justify-content: center;
    align-items: center;
    margin: 0px auto;
    height: 60px;
    width: 100%;
    color: white;
    font-weight: bold;
    font-size: 20px;
}