.loader-swing div {
  border-radius: 50%;
  float: left;
  height: 1em;
  width: 1em;
}

.loader-swing div:nth-of-type(1) {
  background: -webkit-linear-gradient(left, #0becfb 0%, #21ece5 100%);
  background: linear-gradient(to right, #0becfb 0%, #21ece5 100%);
}
.loader-swing div:nth-of-type(2) {
  background: -webkit-linear-gradient(left, #21ece5 0%, #40efc5 100%);
  background: linear-gradient(to right, #21ece5 0%, #40efc5 100%);
}
.loader-swing div:nth-of-type(3) {
  background: -webkit-linear-gradient(left, #40efc5 0%, #42f0c3 100%);
  background: linear-gradient(to right, #40efc5 0%, #42f0c3 100%);
}
.loader-swing div:nth-of-type(4) {
  background: -webkit-linear-gradient(left, #42f0c3 0%, #68f29b 100%);
  background: linear-gradient(to right, #42f0c3 0%, #68f29b 100%);
}
.loader-swing div:nth-of-type(5) {
  background: -webkit-linear-gradient(left, #68f29b 0%, #93f570 100%);
  background: linear-gradient(to right, #68f29b 0%, #93f570 100%);
}
.loader-swing div:nth-of-type(6) {
  background: -webkit-linear-gradient(left, #93f570 0%, #bafa46 100%);
  background: linear-gradient(to right, #93f570 0%, #bafa46 100%);
}
.loader-swing div:nth-of-type(7) {
  background: -webkit-linear-gradient(left, #bafa46 0%, #dcfd23 100%);
  background: linear-gradient(to right, #bafa46 0%, #dcfd23 100%);
}
.loader-shadow {
  clear: left;
  padding-top: 1.5em;
}
.loader-shadow div {
  -webkit-filter: blur(1px);
  filter: blur(1px);
  float: left;
  width: 1em;
  height: .25em;
  border-radius: 50%;
  background: #e3dbd2;
}
.loader-shadow .loader-shadow-l {
  background: #d5d8d6;
}
.loader-shadow .loader-shadow-r {
  background: #eed3ca;
}
@-webkit-keyframes ball-l {
  0%, 50% {
    -webkit-transform: rotate(0) translateX(0);
    transform: rotate(0) translateX(0);
  }
  100% {
    -webkit-transform: rotate(30deg) translateX(-1.5em);
    transform: rotate(30deg) translateX(-1.5em);
  }
}
@keyframes ball-l {
  0%, 50% {
    -webkit-transform: rotate(0) translate(0);
    transform: rotate(0) translateX(0);
  }
  100% {
    -webkit-transform: rotate(30deg) translateX(-1.5em);
    transform: rotate(30deg) translateX(-1.5em);
  }
}
@-webkit-keyframes ball-r {
  0% {
    -webkit-transform: rotate(-30deg) translateX(1.5em);
    transform: rotate(-30deg) translateX(1.5em);
  }
  50%,
  100% {
    -webkit-transform: rotate(0) translateX(0);
    transform: rotate(0) translateX(0);
  }
}
@keyframes ball-r {
  0% {
    -webkit-transform: rotate(-30deg) translateX(1.5em);
    transform: rotate(-30deg) translateX(1.5em);
  }
  50%,
  100% {
    -webkit-transform: rotate(0) translateX(0);
    transform: rotate(0) translateX(0)
  }
}
@-webkit-keyframes shadow-l-n {
  0%, 50% {
    opacity: .5;
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    opacity: .125;
    -webkit-transform: translateX(-0.57em);
    transform: translateX(-0.75em);
  }
}
@keyframes shadow-l-n {
  0%, 50% {
    opacity: .5;
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    opacity: .125;
    -webkit-transform: translateX(-1.75);
    transform: translateX(-0.75em);
  }
}
@-webkit-keyframes shadow-r-n {
  0% {
    opacity: .125;
    -webkit-transform: translateX(0.75em);
    transform: translateX(0.75em);
  }
  50%,
  100% {
    opacity: .5;
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
@keyframes shadow-r-n {
  0% {
    opacity: .125;
    -webkit-transform: translateX(0.75em);
    transform: translateX(0.75em);
  }
  50%,
  100% {
    opacity: .5;
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
.loader-swing-l {
  -webkit-animation: ball-l .425s ease-in-out infinite alternate;
  animation: ball-l .425s ease-in-out infinite alternate;
}
.loader-swing-r {
  -webkit-animation: ball-r .425s ease-in-out infinite alternate;
  animation: ball-r .425s ease-in-out infinite alternate;
}
.loader-shadow-l {
  -webkit-animation: shadow-l-n .425s ease-in-out infinite alternate;
  animation: shadow-l-n .425s ease-in-out infinite alternate;
}
.loader-shadow-r {
  -webkit-animation: shadow-r-n .425s ease-in-out infinite alternate;
  animation: shadow-r-n .425s ease-in-out infinite alternate;
}