.sections-container {
  background-color : transparent;
  display: flex;
  flex-direction: column;
  /* font-family: Muli; */
  width: 100%;
  height: 100%;
  position:relative;
}

.section-card {
  position: relative;
  /* margin : 5px; */
  padding: 15px 0px;
  background-color : transparent;
  /* font-family: Muli; */
}

.section-image img {
  background-color : white;
  border-radius : 20px;
  width: calc(100%);
  height: 210px;
  object-fit: cover;
  position: relative;
}

.section-info {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding-bottom: 20px;
  margin-bottom: 18px;
  display: flex;
  flex-direction: column;
  background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.45), rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.75));
  border-radius: 0px 0px 20px 20px;
}

.section-name {
  padding: 15px 10px 4px 15px;
  font-weight: bold;
  font-size: 20px;
  /* font-family: Muli; */
  color: white;
}

.section-description {
  color: white;
  /* font-family: Muli; */
  padding: 0px 15px;
}

.lds-ring {
  display: inline-block;
  position: absolute;
  width: 80px;
  height: 80px;
  top: calc(50% - 40px);
  left: calc(50% - 40px);
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}