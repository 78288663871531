.confirmationModal {
    font-weight: bold;
    font-family: Muli;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    padding-top: 40px;
    padding: 40px 25px 20px 25px;
}

.confirmationMessage {
    text-align: center;
}

.confirmationButtons {
    display: flex;
    flex-direction: row;
    margin-top: 20px;
    text-align: center;
    width: 100%;
}

.confirmationCancelButton {
    font-size: 20px;
    color: blue;
    width: 50%;
    font-weight: bold;
    text-align: center;
}

.confirmationCloseButton {
    font-size: 20px;
    color: blue;
    width: 50%;
    font-weight: bold;
    text-align: center;
}