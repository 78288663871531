.note-input {
    width: 100%;
    text-align: center;
    margin-top: -60px;
    padding-bottom: 60px;
}

.note-input textarea {
    height: 180px;
    width: 90%;
    border-radius: 10px;
    border-width: 1px;
    border-color: black;
    border-style: solid;
    padding-top: 20;
    padding-left: 20;
    margin: 20px 0px;
    font-size: 18px;
    font-family: Muli;
}

.note-title {
    padding-left: 20px;
    text-align: left;
    font-size: 18px;
    font-weight: bold;
    font-family: Muli;
}