/* .order-content {
    padding: 60px 0px;
} */

.order-list {
    background-color: #F2FAFF;
    margin: 20px 0px;
    font-size: 20px;
}

.order-articles {
    text-align: right;
    padding-right: 20px;
    padding-bottom: 20px;
}

.order-total {
    text-align: right;
    padding-right: 20px;
    font-size: 20px;
    padding-bottom: 20px;
}

.row-product {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    line-height: 100%;
    height: 100%;
}

.row-products {
    display: flex;
    flex-direction: column;
}

.row-subproducts {
    font-size: 15px;
}

.row-subproduct-quantity-name {
    font-size: 15px;
}

.order-costs-title {
    margin: 0px 20px 0px 20px;
    font-size: 20px;
    font-weight: bold;
}

.order-costs-list {
    background-color: #F2FAFF;
    margin: 20px 0px;
    padding: 20px 0px;
    font-size: 20px;
}

.row-cost {
    font-size: 15px;
}

.row-costs {
    display: flex;
    flex-direction: column;
}

.button-modifiers {
    display: flex;
    flex-direction: row;
    margin-top: 10px;
}

.button-modifiers input {
    margin-left: 20px;
}

.row-right {
    display:flex;
    flex-direction: column;
    padding-right: 10px;
    text-align: right;
}

.cost-message {
    margin: 20px 20px -20px 20px;
    font-size: 18px;
}