@media (min-width: 1281px) {
    .summary-footer {
        position: fixed;
        bottom: 0;
        display: flex;
        flex-direction: row;
        background-color: #223F67;
        justify-content: center;
        align-items: center;
        margin: 0px auto;
        height: 60px;
        width: 100%;
        max-width: 600px;
        color: white;
        font-weight: bold;
        font-size: 20px;
    }
}

.inset {
    height: 60px;
}

.summary-footer {
    position: fixed;
    bottom: 0;
    display: flex;
    flex-direction: row;
    background-color: #223F67;
    justify-content: center;
    align-items: center;
    margin: 0px auto;
    height: 60px;
    width: 100%;
    color: white;
    font-weight: bold;
    font-size: 20px;
}