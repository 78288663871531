.confirmationModal {
    font-weight: bold;
    font-family: Muli;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    padding-top: 40px;
    padding: 40px 25px 20px 25px;
}

.confirmationMessage {
    text-align: center;
}

.confirmationButton {
    margin-top: 20px;
    text-align: center;
    width: 100%;
    font-size: 20px;
    color: blue;
    font-weight: bold;
    justify-content: center;
}