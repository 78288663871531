.book {
    font-family: Muli;
    font-size: 16px;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    overflow: auto;
}

.closeBook {
    font-family: Muli;
    font-size: 20px;
    font-weight: bolder;
    padding: 20px 0px 0px 20px;
}

.header {
    margin: 20px;
    font-family: Muli;
    font-size: 16px;
}

.footer {
    margin: -20px 20px 20px 20px;
    font-family: Muli;
    font-size: 12px;
    font-style: italic;
}
  
form .formRow {
    width: 90%;
    align-items: center;
    border-top: 1px solid #a1a1a1;
    margin: 0px 20px;
}
  
.formRow:first-child {
    border-top: none;
}
  
form .formRowLabel {
    width: 40%;
    min-width: 50px;
    padding: 11px 0;
    color: black;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.formRowInput:-webkit-autofill {
    -webkit-text-fill-color: blue;
    /* Hack to hide the default webkit autofill */
    transition: background-color 100000000s;
    animation: 1ms void-animation-out;
}
  
.formRowInput {
    font-size: 16px;
    color: black;
    background-color: transparent;
    animation: 1ms void-animation-out;
}
  
.formRowInput::placeholder {
    color: #999999;
}

.guestbook-submit {
    display: block;
    font-weight: bold;
    font-size: 20px;
    border-radius: 25px;
    width: 50%;
    height: 50px;
    background-color: #223F67;
    color: white;
    text-align: center;
    margin: 20px auto 0px auto;
}

.guestbook-toggle {
    margin-top: 30px;
    display: flex;
    flex-direction: row;
}
  
.guestbook-slider-title {
    margin-left: 10px;
    /* line-height: 12px; */
    font-size: 12px;
    max-width: 70%;
}
  
/* The switch - the box around the slider */
.guestbook-switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
    margin-left: 20px;
}
  
/* Hide default HTML checkbox */
.guestbook-switch input {
    opacity: 0;
    width: 0;
    height: 0;
}
  
/* The slider */
.guestbook-slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}
  
.guestbook-slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}
  
input:checked + .guestbook-slider {
    background-color: #2196F3;
}
  
input:focus + .guestbook-slider {
    box-shadow: 0 0 1px #2196F3;
}
  
input:checked + .guestbook-slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}
  
/* Rounded sliders */
.guestbook-slider.round {
    border-radius: 34px;
}
  
.guestbook-slider.round:before {
    border-radius: 50%;
}