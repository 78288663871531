@media (min-width: 1281px) {
    .bottom-container {
        /* height: 64px; */
        height: 90px;
        position: fixed;
        /* bottom: 0; */
        top: 10px;
        right: 0;
        z-index: 1000;
        /* width: 100%; */
        width: 90px;
        display: grid;
        max-width: 600px;
        justify-content: right;
    }
}

.bottom-container {
    /* height: 64px; */
    height: 90px;
    position: fixed;
    /* bottom: 0; */
    top: 10px;
    right: 0;
    z-index: 1000;
    /* width: 100%; */
    width: 90px;
    display: grid;
    justify-content: right;
}

.cart-container {
    display: flex;
    flex-direction: row;
    /* justify-content: space-between; */
    justify-content: center;
    background-color: #223F67;
    /* border-radius: 27px; */
    border-radius: 30px;
    border: 2px solid white;
    color: white;
    font-family: Muli;
    font-weight: bold;
    font-size: 18px;
    align-items: center;
    /* height: 54px; */
    height: 60px;
    box-sizing: border-box;
    /* width: 75%; */
    width: 60px;
    /* margin: 0 auto; */
    margin-right: 10px;
    /* grid-column: 1;
    grid-row: 1; */
}

.cart-count {
    top: 10px;
    right: 18px;
    position: absolute;
    color: #223F67;
    background-color: yellow;
    height: 16px;
    width: 16px;
    border-radius: 8px;
    line-height: 14px;
    font-size: 12px;
    font-weight: bold;
    font-family: Muli;
    text-align: center;
}

.cart-total {
    padding: 0px 20px;
}

.cart-order {
    margin: 0 auto;
    color: white;
    font-family: Muli;
    font-weight: bold;
    font-size: 18px;
    text-align: center;
    line-height: 18px;
}