.appero-footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background-color: transparent;
    padding-top: 40px;
}

.appero-footer-legal {
    /* font-family: Muli; */
    font-size: 14px;
    font-style: italic;
    margin: 20px 0px;
    padding: 0px 20px;
    white-space: pre-line;
}

.social-networks {
    display: flex;
    flex-direction: row;
    width: 100%;
    text-align: center;
    align-items: center;
    justify-content: center;
}

.social-networks-instagram img {
    border-radius: 8px;
    margin: 10px;
    height: 40px;
    width: auto;
}

.social-networks-facebook img {
    border-radius: 8px;
    margin: 10px;
    height: 40px;
    width: auto;
}

.social-networks-tripadvisor img {
    border-radius: 8px;
    margin: 10px;
    height: 40px;
    width: auto;
}

.social-networks-gmaps img {
    border-radius: 8px;
    margin: 10px;
    height: 40px;
    width: auto;
}

.social-networks-website {
    margin: 10px;
}

a.appero-footer-powered {
    color: lightslategray;
    font-size: 12px;
    font-weight: bolder;
    width: 100%;
    text-decoration: none;
    text-align: center;
    margin: 10px 0px;
}