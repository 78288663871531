html {
  color : #444;
}

body {
  touch-action: manipulation;
  user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  -webkit-touch-callout: none;
  -o-user-select: none;
  -moz-user-select: none;
}

@media (min-width: 1281px) {
  .main-container {
    height : 100%;
    width : 100%;
    max-width: 600px;
    position : absolute;
    display : flex;
    flex-direction : column;
  }

  img {
    max-width: 600px;
  }
}

.main-container {
  height : 100%;
  width : 100%;
  position : absolute;
  display : flex;
  flex-direction : column;
  font-family: Muli;
}

.logo-container {
  text-align : center;
}

.logo-container img {
  width : 150px;
  margin : 15px;
  display: none
}

.badges-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-grow: 0;
  padding : 15px 0 15px 0;
  background-color : white;
}

.badges-text {
  background: white;
  text-align: center;
  padding: 5px 3px;
  border-top: 1px solid #DDD;
}

.apple-logo {
  height : 40px;
}

.google-logo {
  height : 40px;
  width  : 134px;
}

/*
Menu
 */

.title-container {
  /* padding : 20px 15px; */
  display : flex;
  flex-direction : row;
  justify-content: space-between;
  background-color : white;
}

.title-left {
  font-weight: bold;
  font-size : 16px;
  background-color : white;
}

.title-right {
  font-size : 14px;
  background-color : white;
}


/*
Categories
 */

.categories-container {
  display : flex;
  flex-direction : row;
  background-color: white;
  justify-content: flex-start;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
  /* font-family: Muli; */
  position: relative;
}

.category {
  border-radius : 12px;
  border : 1px solid rgba(219, 219, 219, 0.2);
  padding : 12px;
  white-space: nowrap;
  background-color: rgba(219, 219, 219, 0.2);
  margin: 10px 5px 10px 5px;
  display: inline-flex;
  height: 30px;
  align-items: center;
  line-height: 30px;
}

.selected {
  background-color : #223F67;
  border-color : #223F67;
  color : white;
}

/*
Products
 */
.products-container {
  background-color : white;
  padding : 5px 0px;
  flex : 1;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
  /* font-family: Muli; */
  position: relative;
  margin-top: -1px;
}

.product-category-name {
  display: flex;
  font-size: 26px;
  font-weight: bolder;
  color: #223F67;
  padding: 0px 10px;
  min-height: 40px;
  line-height: 40px;
}

.product-category-tagline {
  font-size: 16px;
  font-weight: bolder;
  color: #223F67;
  padding: 0px 10px;
  margin-bottom: 10px;
}

.product-card {
  background-color : transparent;
  min-height: 110px;
  width: 100%;
  border-top: 1px solid lightgray;
  display: flex;
  flex-direction: column;
  vertical-align: middle;
  animation: fade 200ms ease-out;
}

.added {
  background: linear-gradient(to right, #223F67 0px, #223F67 6px, white 6px);
}

.product-quantity-name {
  display: flex;
  flex-direction: row;
  margin-top: 2px;
  position: relative;
}

.quantity {
  color: #223F67;
  font-weight : bold;
  margin-top: 5px;
  padding-right: 5px;
}

.product {
  display : flex;
  flex-direction : row;
}

.product.unavailable {
  color : #BBB;
  text-decoration: line-through;
}

.product-description {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 0px 10px;
  width: 100%;
  position: relative;
}

.name {
  margin-top: 5px;
  font-weight : bold;
}

.recipe {
  font-size : 14px;
  /* height: 45px; */
  padding: 10px 0;
  /* display: flex; */
  align-items: center;
  width: 100%;
  position: relative;
  
}

.price {
  display: flex;
  font-weight: bold;
  margin-bottom: 2px;
  align-items: center;
}

.product-image {
  height: 75px;
  width: 75px;
  margin: 18px 10px 17px 10px;
  border-radius: 4px;
  display:table-cell;
  vertical-align:middle;
  text-align:center;
  align-items: center;
}

.product-image img {
  height: 75px;
  width: 75px;
  border-radius : 4px;
  object-fit: cover;
}
